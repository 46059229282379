<template>
  <div class="section section-event-list">
    <div class="section-body main">
      <div class="biletum-tabs">
        <a class="biletum-tab"
           :href="$router.resolve({
                name: 'event-list-my'
              }).href"
           :class="{'biletum-tab-current' : $route.name === 'event-list-my'}"
        >
          {{ $t('events.list.myEventsTab') }}
        </a>
        <a class="biletum-tab"
           @click="setTab(1)"
           :href="$router.resolve({
                name: 'event-list'
              }).href"
           :class="{'biletum-tab-current' : $route.name === 'event-list'}"
        >
          {{ $t('events.list.allEventsTab') }}
        </a>
        <span
          class="biletum-tab"
          style="vertical-align: middle; padding-top: 0.5rem;"
          v-if="$route.name === 'event-list'"
          @click="showFilter = !showFilter"
        >
          <icon-feather-filter class="biletum-tab" :color="showFilter ? '#00B6F8' : '#000'"/>
        </span>
      </div>
      <events-filter
        :toDefault="list.type"
        :showFilter.sync="showFilter"
        @eventsFilterData="setFiltersData"
        @eventsFiltered="eventsFiltered()"
      />
      <div>
        <mu-circular-progress
          v-if="eventsPageLoading"
          class="loading-indicator"
          :size="48"
        />
        <!--<mu-circular-progress v-if="!eventList && !eventListPast" class="loading-indicator"
                              :size="36"></mu-circular-progress>-->
        <transition name="fadepastevents">
          <div class="past-events past-events-my relative"
               ref="pastEventsBlock"
               v-if="isPastEventsBlockVisible"
          >
            <div class="controls">
              <div class="control control-left"
                   :class="{ 'inactive' : pastEventsSliderPosition.marginLeft >= 0 }"
                   @click="pastEventsSlider('right')"
              ></div>
              <div class="control control-right"
                   :class="{ 'inactive' : Math.abs(pastEventsSliderPosition.marginLeft) >= pastEventsSliderPosition.maxMargin }"
                   @click="pastEventsSlider('left')"
              ></div>
            </div>
            <h3 class="event-list-name">{{ $t('events.listHeaderPast') }}</h3>
            <div class="past-events-wrap">
              <div class="list-contents"
                   ref="pastEventsList"
                   :style="{ width: setPastEventsWidth() }"
              >
                <div class="past-ev"
                     v-for="(ev) in eventListPastReversed"
                     :key="ev.id"
                     @click="eventClickHandler(ev.id, $event)">
                  <div class="image-wrap"
                       v-if="ev.image"
                       :style="{ backgroundImage: 'url(' + ev.image + ')' }"
                  ></div>
                  <div class="image-wrap event-image-unavailable"
                       v-else
                  ></div>
                  <div class="past-ev-content">
                    <div class="title">{{ ev.title }}</div>
                    <div class="info">
                      <div class="part part-dates" v-html="formatDate(ev)"></div>
                      <div class="part part-location"
                           v-if="ev.city || ev.country"
                      >
                        <span class="country" v-if="ev.country">{{ev.country.name}}</span>
                        <span v-if="ev.city">,</span> <span class="city" v-if="ev.city">{{ev.city.name}}</span>
                      </div>
                      <div class="part part-location part-location-empty"
                           v-else
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div v-if="reversedEventList && !eventsPageLoading">
          <h3 class="event-list-name"
              v-if="(list.type === 'own' || $route.name === 'event-list-my') && eventListPast && eventListPast.length"
          >
            {{ $t('events.listHeaderUpcoming') }}
          </h3>
          <mu-paper
            class="event"
            v-for="(ev) in reversedEventList"
            :key="ev.id"
            :z-depth="0"
            @click="eventClickHandler(ev.id, $event)"
          >
            <div class="info relative">
              <div class="type relative">
                <span
                  class="inline-block"
                  style="height: 1.75rem;"
                />
                <span class="type-content">{{ ev.event_type.name }}</span>
              </div>
              <div
                class="date"
                v-html="formatDate(ev)"
              />
              <div class="location">
                <span class="country" v-if="ev.country">{{ev.country.name}}</span>
                <span class="city" v-if="ev.city">{{ev.city.name}}</span>
              </div>
              <div class="actions">
                <div class="favorite">
                  <div
                    class="action-content"
                    v-if="!ev.personal.is_creator"
                    @click.stop="toggleFavorite(ev, $event)"
                  >
                    <icon-feather-star :fill="ev.personal.is_favorite" />
                  </div>
                </div>
                <div class="share">
                  <div class="action-content">
                    <icon-feather-share @click.native.stop="toggleSharer(ev.id, $event)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative image-wrap">
              <div class="image-wrap-proportion"></div>
              <div v-if="ev.image"
                   class="event-image event-image-available"
                   :style="{ backgroundImage: 'url(' + ev.image + ')' }"
              ></div>
              <div v-else
                   class="event-image event-image-unavailable"
              ></div>
            </div>
            <div class="content">
              <div class="title-and-descr">
                <div class="title">{{ev.title}}</div>
                <div class="descr">{{ev.descr}}</div>
              </div>
              <div class="actions">
                <div></div>
                <div
                  v-if="ev.access_type === 'register'"
                  class="event-action-buttons"
                >
                  <mu-button
                      class="event-action-button"
                      v-if="ev.buy_booth_url"
                      round
                      small
                      color="secondary"
                      :href="ev.buy_booth_url"
                      @click.stop=""
                      target="_blank"
                  >
                    <span class="full-text">{{ $t('button.requestABooth') }}</span>
                    <span class="short-text">{{ $t('button.requestABoothShort') }}</span>
                  </mu-button>
                  <mu-button
                      class="event-action-button"
                      round
                      small
                      color="primary"
                      @click.stop=""
                      :href="ev.buy_ticket_url"
                      v-if="ev.buy_ticket_url"
                      target="_blank"
                  >
                    <span class="full-text">{{ $t('button.buyTickets') }}</span>
                    <span class="short-text">{{ $t('button.buyTicketsShort') }}</span>
                  </mu-button>
                </div>
<!--                <div v-else class="event-action-buttons event-action-buttons-register">-->
<!--                  <div class="event-online-statuses">-->
<!--                    <div class="event-online-status event-online-status-online  event-online-status-inactive">-->
<!--                      {{ $t('events.page.eventOnline') }}-->
<!--                    </div>-->
<!--                    <div class="event-online-status event-online-status-offline event-online-status-active">-->
<!--                      {{ $t('events.page.eventOffline') }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </mu-paper>
        </div>

        <div
          v-if="reversedEventList && !reversedEventList.length"
          class="no-events-found"
          :class="{'vh-100': eventListPast && !eventListPast.length}"
        >
          <div class="no-events-block">
            <div class="icon">
              <icon-schedule />
            </div>
            <div class="text">{{ $t('events.noEventsYet') }}</div>
            <button class="control control-button create-event-btn" @click="createEvent()">
              {{ $t('navigation.createEvent') }}
            </button>
          </div>
        </div>

      </div>
      <div class="push"></div>
    </div>
<!--    <footer class="footer-nav" v-if="!eventsPageLoading && listTotal > 0">-->
<!--      <mu-container>-->
<!--        <mu-flex justify-content="center">-->
<!--          <mu-pagination :page-size="query.limit"-->
<!--                         :total="listTotal"-->
<!--                         :current.sync="current" />-->
<!--        </mu-flex>-->
<!--      </mu-container>-->
<!--    </footer>-->
    <div class="event-list-footer">
      <a href="/documents/privacy_policy.html">{{ $t('authPage.privacyPolicy') }}</a>
      <a href="/documents/terms-of-use.html">{{ $t('authPage.termsOfUse') }}</a>
      <a href="/documents/cookie_policy.html">{{ $t('authPage.cookiePolicy') }}</a>
      <a href="/documents/user-agreement.html">{{ $t('authPage.userAgreement') }}</a>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import IconFeatherShare from '@/_modules/icons/components/icon-feather-share.vue';
import iconFeatherStar from '@/_modules/icons/components/icon-feather-star.vue';
import iconSchedule from '@/_modules/icons/components/icon-schedule.vue';
import eventsFilter from './components/eventsFilter/eventsFilter';
import iconFeatherFilter from '@/_modules/icons/components/icon-feather-filter.vue';

export default {
  name: 'event-list',
  components: {
    IconFeatherShare,
    iconFeatherStar,
    iconSchedule,
    eventsFilter,
    iconFeatherFilter
  },
  computed: {
    ...mapState({
      eventList: state => state.eventStore.eventList.List,
      eventListPast: state => state.eventStore.eventListPast.List,
      listTotal: state => state.eventStore.eventList.Total,
      loadAddFavorite: state => state.eventStore.eventList.loadAddFavorite,
      loadDelFavorite: state => state.eventStore.eventList.loadDelFavorite,
      eventError: state => state.eventStore.eventList.eventError,
      eventsPageLoading: state => state.eventStore.eventList.eventsPageLoading,
      eventsPageSuccess: state => state.eventStore.eventList.eventsPageSuccess,
    }),
    ...mapGetters({
      isSharerVisible: 'ewSharerStore/isSharerVisible',
    }),
    eventListPastReversed() {
      if (this.eventListPast && this.eventListPast.length) {
        return this.eventListPast.slice().reverse();
      }
      return [];
    },
    isPastEventsListEmpty() {
      return this.eventListPastReversed.length === 0;
    },
    isPastEventsBlockVisible() {
      return this.isListTypeOwnEvents && !this.isPastEventsListEmpty;
    },
    isListTypeOwnEvents() {
      return (this.list.type === 'own' || this.$route.name === 'event-list-my');
    },
    reversedEventList() {
      if (this.eventList && this.eventList.length) {
        return this.isListTypeOwnEvents ? this.eventList : this.eventList.slice().reverse();
      }
      return [];
    }
  },
  watch: {
    isListTypeOwnEvents: {
      handler(newVal) {
        if (newVal === true) {
          this.getPastEvents();
        }
      }
    },
    eventListPast: {
      deep: true,
      handler() {
        this.setPastEventsWidth();
        this.$nextTick(this.updatePastSliderMaxMargin); // Counts initial maxMargin instead of hardcoded default value
      }
    },
    current: {
      handler() {
        if (this.current === 1) {
          this.query.offset = 0
        } else {
          let computed = this.offsetVar * this.current - this.offsetVar;
          if (this.query.offset > this.listTotal) {
            computed = this.offsetVar * this.current - this.offsetVar;
          }
          this.query.offset = computed
        }
        this.pagination(this.query.offset, this.query.limit)
      }
    },
    eventError: {
      deep: true,
      handler() {
        this.setError();
      }
    }
  },
  data: () => ({
    pastEventsSliderPosition: {
      marginLeft: 0,
      maxMargin: 1088
    },
    sharer: {
      previous_id: null,
      url: window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search + window.location.hash,
      position: {
        t: 0,
        l: 0
      },
      sharingTexts: {
        text: '',
        title: ''
      }
    },
    current: 1, // TODO: better naming, this seems to be related to page number
    errorText: '',
    list: {
      type: 'all'
    },
    query: {
      offset: 0,
      limit: 500
    },
    totalPages: 0,
    tab: null,
    showFilter: false,
    formData: {
      country_id: null,
      city_id: null,
      start_from: null,
      start_to: null,
      end_from: null,
      end_to: null,
      categories: null,
      event_types: [],
    },
  }),
  created() {
    this.setTab(1); // Sets that tab to «all events», see setTab/To do
    if (this.isListTypeOwnEvents) {
      this.getPastEvents();
    }
    if (this.$route.query && this.$route.query.kw && this.$route.query.kw.trim() !== '') {
      this.showFilter = true;
    }

    this.eventsFiltered();
  },
  mounted() {
    window.addEventListener('resize', this.updatePastSliderMaxMargin);
  },
  onBeforeDestroy(){
    window.removeEventListener('resize', this.updatePastSliderMaxMargin);
  },
  methods: {
    ...mapActions({
      openSharer: 'ewSharerStore/openSharer',
      closeSharer: 'ewSharerStore/closeSharer',
    }),
    pastEventsSlider(direction) {
      const viewport = this.$refs.pastEventsBlock;
      const list = this.$refs.pastEventsList;
      const viewportWidth = viewport.getBoundingClientRect().width;
      const singleEvent = this.$refs.pastEventsList.getElementsByClassName('past-ev')[0];
      const singleEventMargin = parseInt(window.getComputedStyle(singleEvent).marginRight, 10);
      const singleEventWidth = singleEvent.getBoundingClientRect().width + singleEventMargin;
      let shiftBy = Math.floor(viewportWidth / singleEventWidth) * singleEventWidth;
      let newMargin = list.style.marginLeft ? parseInt(list.style.marginLeft, 10) : 0;
      switch (direction) {
        case 'left':
          newMargin = newMargin - shiftBy;
          break;
        case 'right':
          newMargin = newMargin + shiftBy;
          break;
        default:
          void (0);
      }
      this.pastEventsSliderPosition.maxMargin = list.getBoundingClientRect().width - viewportWidth;
      if (direction) {
        this.pastEventsSliderPosition.marginLeft = newMargin;
        list.style.marginLeft = newMargin !== 0 ? newMargin + 'px' : 0;
      }
    },
    updatePastSliderMaxMargin() {
      const viewport = this.$refs.pastEventsBlock;
      const list = this.$refs.pastEventsList;

      if (!viewport || !list) {
        return;
      }

      const viewportWidth = viewport.getBoundingClientRect().width;
      this.pastEventsSliderPosition.maxMargin = list.getBoundingClientRect().width - viewportWidth;
    },
    setPastEventsWidth() {
      const itemWidth = 182;
      const marginRight = 11;
      if (this.eventListPast) {
        return (((itemWidth + marginRight) * this.eventListPast.length - marginRight) + 'px');
      } else {
        return '100%';
      }
    },
    getPastEvents() {
      this.$store.dispatch('eventStore/callEventListMyPast', {
        end_to: this.$moment().utc().subtract(1, 'minute').format('YYYY-MM-DDTHH:mm'),
        limit: 50
      });
    },
    setTab(val) {
      // TODO: refactor. Is this method needed? Use router.push maybe?
      this.tab = Number(val);
      switch (this.tab) {
        case 0:
          this.list.type = 'own';
          break;
        case 1:
          this.list.type = 'all';
          break;
        default:
          this.list.type = 'all';
      }
    },
    eventsFiltered() {
      const cleanEmpty = (obj) => {
        let newObj = {};
        Object.keys(obj).forEach((prop) => {
          if (prop === 'end_from') {
            if (!obj[prop]) {
              newObj[prop] = this.isListTypeOwnEvents ?
                this.$moment().format('YYYY-MM-DDTHH:mm') :
                this.$moment().subtract(5, 'years').format('YYYY-MM-DDTHH:mm')
            } else {
              newObj[prop] = obj[prop];
            }
          } else if (obj[prop] !== null && obj[prop] !== '') {
            newObj[prop] = obj[prop];
          }
        });
        return Object.assign(newObj, this.query);
      };

      let actionName = 'callEventListAll';
      if (this.list.type === 'own' || this.$route.name === 'event-list-my') {
        actionName = 'callEventListMy';
      }
      this.$store.dispatch('eventStore/' + actionName, cleanEmpty(this.formData));
    },
    toggleSharer(id, event) {
      const urlToShare = window.location.protocol + '//' + window.location.host + this.$router.resolve({
        name: 'event-info',
        params: { eventId: '' + id},
      }).href;

      if (this.sharer.previous_id === id) {
        this.closeSharer();
        this.sharer.previous_id = null;
        return false;
      } else {
        this.openSharer({ eventTarget: event.target, url: urlToShare});
      }
      this.sharer.previous_id = id;

      return false;
    },
    toggleFavorite(ev) {
      if (ev.personal.is_favorite) {
        this.$store.dispatch('eventStore/deleteFavorite', ev.id);
      } else {
        this.$store.dispatch('eventStore/addFavorite', ev.id);
      }
      ev.personal.is_favorite = !ev.personal.is_favorite;
    },
    eventClickHandler(id, event, subRoute = '') {
      // TODO: different routes when we decide to put booth and tickets buttons back again
      switch (subRoute) {
        case 'booth':
          this.$router.push({name: 'event-info', params: { eventId: '' + id}});
          break;
        case 'tickets':
          this.$router.push({name: 'event-info', params: { eventId: '' + id}});
          break;
        default:
          this.$router.push({name: 'event-info', params: { eventId: '' + id}});
      }
    },
    setFiltersData(data) {
      this.query.offset = 0;
      Object.assign(this.formData, data)
    },
    setError() {
      if (!this.eventError) {
        return;
      }
      this.errorText = this.eventError.message_key || '';
    },
    formatDate(ev) {
      let html = '';
      const $moment = this.$moment;

      const year_start = $moment(ev.date_start).format('YYYY');
      const year_end = $moment(ev.date_end).format('YYYY');

      const month_start = $moment(ev.date_start).format('MM');
      const month_end = $moment(ev.date_end).format('MM');

      const day_start = $moment(ev.date_start).format('DD');
      const day_end = $moment(ev.date_end).format('DD');

      const year_current = $moment().format('YYYY');
      let y_start_html = '';
      if (year_current !== year_start) {
        y_start_html = '<span class="year">' + year_start + '</span>';
      }

      if (year_start !== year_end) {
        // Fully different dates
        const m_start = $moment(ev.date_start).format('MMMM');
        const d_start = $moment(ev.date_start).format('DD');
        html = '<span class="period period-different"><span class="period-start"><span class="month">' + m_start + '</span><span class="day">' + d_start + '</span>' + y_start_html + '</span></span>';
      } else if (month_start !== month_end) {
        // Same year, different months
        const m_start = $moment(ev.date_start).format('MMMM');
        const d_start = $moment(ev.date_start).format('DD');
        html = '<span class="period period-same-year"><span class="period-start"><span class="month">' + m_start + '</span><span class="day">' + d_start + '</span>' + y_start_html + '</span></span>';
      } else if (day_start !== day_end) {
        // Same month, different days
        const m = $moment(ev.date_start).format('MMMM');
        const d_start = $moment(ev.date_start).format('DD');
        const d_end = $moment(ev.date_end).format('DD');
        html = '<span class="period period-same-month"><span class="month">' + m + '</span><span class="day">' + d_start + '-' + d_end + '</span>' + y_start_html + '</span>';
      } else {
        // One-day event
        const m = $moment(ev.date_start).format('MMMM');
        const d = $moment(ev.date_start).format('DD');
        html = '<span class="period period-same-month period-same-day"><span class="month">' + m + '</span><span class="day">' + d + '</span>' + y_start_html + '</span>';
      }

      return html;

    },
    createEvent() {
      this.$router.push({ name: 'event-create' })
    }
  }
}
</script>

<style scoped lang="scss">

  .loading-indicator {
    display: block;
    margin: 2rem auto 0;
    left: 50%;
    right: 50%;
    z-index: 99;
    position: absolute;
  }

  .footer-nav,
  .push {
    height: 50px;
  }

  .event-list-footer {
    text-align: left;
    padding: 60px 30px 120px;
    font-size: 12px;
    line-height: 14px;
    max-width: 1138px;
    margin: 0 auto;

    a {
      display: inline-block;
      margin-right: 24px;
      color: rgba(0, 0, 0, 0.4);

      &:hover,
      &:focus {
        color: $ew-red;
      }
    }
  }

  .event {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 254px;
    margin: 0 0 50px;
    border-radius: 25px;

    /* box-shadow: 30px 30px 80px rgba(55, 84, 170, 0.05); */
    box-shadow: 5px 5px 15px rgba(55, 84, 170, 0.1);
    line-height: 1.25;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 800px) {
      border-radius: 20px;
      height: 220px;
    }

    @media (max-width: 640px) {
      border-radius: 10px;
      height: 190px;
    }

    .info {
      width: 168px;
      max-width: 168px;
      box-sizing: border-box;
      padding: 27px 38px 27px 32px;
      text-align: left;

      @media (max-width: 800px) {
        padding: 20px 30px 20px 22px;
      }

      @media (max-width: 640px) {
        padding: 16px 24px 16px 18px;
      }

      .type {
        color: $ew-blue;
        font-weight: bold;
        font-size: 1.4rem;
        margin-bottom: 18px;

        .type-content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 2;
        }
      }

      .date {
        margin-bottom: 9px;
      }


      .actions {
        text-align: center;
        position: absolute;
        bottom: 13px;
        left: 0;
        width: 100%;

        .mu-checkbox-checked {
          color: #00b6f8;
        }

        & > * {
          display: inline-block;
          margin: 0 1.1rem;
          width: 24px;
          height: 30px;
        }
      }

      & /deep/ .period {
        white-space: nowrap;

        .month {
          display: block;
          font-size: 1.8rem;
          font-weight: 300;
        }

        .day {
          font-size: 3.6rem;
          display: inline-block;
        }

        .year {
          display: inline-block;
          vertical-align: top;
          line-height: 2;
          padding-left: 0.5rem;
        }

      }

      & /deep/ .period-different,
      & /deep/ .period-same-year {
        .period-end > * {
          display: inline-block;
          margin-right: 0.4rem;
        }

        .period-end > *:last-child {
          margin-right: 0;
        }

        .period-end .month {
          font-size: 1.3rem;
        }

        .period-end .day {
          font-size: 1.9rem;
        }

        .period-end {
          display: block;
        }
      }

      .location {
        font-size: 1.4rem;
        font-weight: 300;
        max-width: 100%;

        .city,
        .country {
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

    }

    & .image-wrap {
      width: 254px;
      background-color: #ccc; /* Background color for when the image hasn't been loaded yet */

      .image-wrap-proportion {
        padding-bottom: 100%;
      }

      .event-image {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .event-image-unavailable {
        background-image: url("../assets/images/no-image.png");
      }

    }

    .flex-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .content {
      display: flex;
      width: 60%;
      padding: 27px 38px 27px 32px;
      flex-flow: column;
      align-items: flex-start;
      justify-content: space-between;

      .title {
        font-size: 2.5rem;
        color: #000;
        text-align: left;
        max-height: 126px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 2.2rem;
      }

      .descr {
        font-weight: 300;
        max-height: 2.5em;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .actions {
        width: 100%;
        justify-self: flex-end;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .event-stats-owner {
        display: flex;
        text-align: center;
        color: #000;
        font-weight: 100;
        font-size: 1.4rem;

        .icon {
          display: block;
          margin-bottom: 1rem;
        }

      }

      .event-stat-item {
        margin-right: 22px;
        opacity: 0.6;

        @media (max-width: 960px) {
          margin-right: 11px;
        }

        &:last-child {
          margin-right: 0;
        }
      }


    }

    .event-action-buttons {
      display: flex;
      align-items: flex-end;
      flex-wrap: nowrap;
    }

    a.event-action-button,
    a.event-action-button:hover,
    a.event-action-button:focus {
      color: #fff;
      text-decoration: none;
    }

    .event-action-button {
      text-transform: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      margin-left: 33px;
      width: 159px;
      text-align: center;

      &.mu-primary-color {
        background-color: #00b6f8;
      }

      &.mu-secondary-color {
        background-color: $ew-red-lighter;
      }

      .short-text {
        display: none;

        @media (max-width: 960px) {
          display: inline;
        }
      }

      .full-text {
        @media (max-width: 960px) {
          display: none;
        }
      }

      @media (max-width: 1088px) {
        width: auto;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        margin-left: 16px;
      }

      @media (max-width: 960px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: 10px;
      }
    }

  }

  .no-events-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;

    font-size: 2.4rem;
    //opacity: 0.4;
    padding: 40px 0;
    text-align: center;
    font-weight: 300;

    .no-events-block {
      .icon {
        margin-bottom: 20px;
      }

      .text {
        color: rgba(0, 0, 0, 1);
        opacity: 0.4;
        margin-bottom: 38px;
      }

      .create-event-btn {
        background: #00b6f8;
        border-radius: 33px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        color: #fff;
        height: 40px;
        width: 196px;
        padding: 0 30px;
        min-width: 237px;
        margin: 0 auto;
      }
    }
  }

  .biletum-tabs {
    padding-left: 32px;
  }

  .biletum-tab {
    cursor: pointer;
    display: inline-block;
    margin-right: 4.1rem;
    color: #000;
    position: relative;
    transition: all 0.2s;

    &:hover {
      color: #00b6f8;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      height: 1px;
      background-color: transparent;
      transition: all 0.4s;
      width: 0.1%;
      content: "";
    }

    &-current {
      color: #00b6f8;

      &::after {
        background-color: #00b6f8;
        width: 100%;
      }
    }
  }

  .fadepastevents-enter-active,
  .fadepastevents-leave-active {
    transition: opacity 0.5s;
  }

  .fadepastevents-enter,
  .fadepastevents-leave-to {
    opacity: 0;
  }

  .event-online-statuses {
    display: inline-block;
    text-align: right;
    font-size: 2rem;

    .event-online-status {
      display: inline-block;
      margin-left: 2.5rem;
      margin-bottom: 0 !important;
    }

    & > :first-child {
      margin-bottom: 2.1rem;
    }
  }

  .event-online-status-inactive {
    color: #000;
    opacity: 0.25;
  }

  .event-online-status-active,
  .i-will-go .label {
    color: $ew-red-lighter;
  }

  .vh-100 {
    height: calc(100vh - 184px);
  }

  .title-and-descr {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 620px;
  }

</style>
